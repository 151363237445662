import { useState, memo } from "react"
import { Box, Flex, Text, Skeleton } from "@chakra-ui/react"
import { WishListEditForm } from "./WishListEditForm"

const WishListCreate: React.FC<{ loading?: boolean }> = ({ loading = false }) => {
  const [expanded, setExpanded] = useState(false)

  if (loading) {
    return <Skeleton h="90px" w="full" startColor="brand.offWhite" endColor="brand.midSand" />
  }

  return (
    <Box cursor="pointer" bg="brand.midSand" p={[4, 4, 6]} border="1px" borderStyle="dashed">
      <Flex alignItems="center" justifyContent="center" onClick={() => setExpanded(!expanded)}>
        <Flex columnGap={4} alignItems="center">
          <Flex bg="brand.offWhite" justifyContent="center" alignItems="center" h={10} w={10} borderRadius="round">
            <Text fontSize="2xl">{expanded ? "-" : "+"}</Text>
          </Flex>
          <Text fontSize={["md", "md", "xl"]}>Create New Wishlist</Text>
        </Flex>
      </Flex>
      {expanded && <WishListEditForm handleCancelClick={() => setExpanded(false)} />}
    </Box>
  )
}

const MemoWishListCreate = memo(WishListCreate)
export { MemoWishListCreate as WishListCreate }
