import { useRef, useState, memo, useCallback } from "react"
import { Box, Button, Flex, Input, Text } from "@chakra-ui/react"
import { useWishListContext } from "@app/providers/wishlist"
import { CloseIcon } from "@chakra-ui/icons"

type WishListEditFormProps = {
  wishlist?: any
  handleCancelClick?: () => void
}

const WishListEditForm: React.FC<WishListEditFormProps> = ({ wishlist, handleCancelClick }) => {
  const refName = useRef<any>()
  const [, /* error */ setError] = useState(false)
  const { deleteWishList, tryCreateWishList } = useWishListContext()

  const onSaveClick = useCallback(() => {
    setError(false)
    if (refName?.current?.value?.length < 2) {
      setError(true)
    } else {
      tryCreateWishList?.(refName?.current?.value)
      handleCancelClick?.()
    }
  }, [handleCancelClick, tryCreateWishList])

  return (
    <Box pos="relative" alignItems="flex-end" display={{ base: "block", sm: "flex" }} onClick={e => e.preventDefault()} pt={8} as="form">
      <Box mr={8} mb={[4, 0]} w={["full", "half"]}>
        <Text mb={2} color="brand.error">
          Name must be longer than two characters
        </Text>
        <Input ref={refName} placeholder="Name your wishlist" w="full" h={12} bgColor="brand.offWhite" />
      </Box>
      <Flex flexDir="column" w={["full", "half"]} alignItems={["flex-end"]}>
        <Flex alignItems="center" mb={2} cursor="pointer" onClick={handleCancelClick} columnGap={4}>
          <Text fontSize="md" textDecoration="underline">
            Cancel
          </Text>
          <CloseIcon />
        </Flex>
        <Flex w="full">
          <Button flex={1} onClick={onSaveClick} h={12} type="submit">
            Save
          </Button>
          {wishlist && !wishlist?.default && (
            <Button flex={1} onClick={() => deleteWishList(wishlist?.lid)} h={12}>
              Delete
            </Button>
          )}
        </Flex>
      </Flex>
    </Box>
  )
}

const MemoWishListEditForm = memo(WishListEditForm)
export { MemoWishListEditForm as WishListEditForm }
