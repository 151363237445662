import { memo } from "react"
import { Box, Button, Flex, HStack, Text } from "@chakra-ui/react"
import { CustomIcon } from "@components/Icon"
import { WishListShareButton } from "./WishListShareButton"
import { CustomLink } from "@components/Link"
import { useCore } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"

type WishListCardProps = {
  wishlist: GatsbyTypes.SanityShoppingListCuration
  showGmLogo?: boolean
  handleAddAllToCart: () => void
  addToCartLoading: string
  isSwym?: boolean
}

const WishListCard: React.FC<WishListCardProps> = ({
  wishlist,
  showGmLogo = false,
  handleAddAllToCart,
  isSwym = false,
  addToCartLoading = "",
}) => {
  const {
    helpers: { handleize },
  } = useCore()
  const {
    settings: { params },
  } = useConfigContext()
  const shareParamKey = isSwym ? params.swymWishListId : params.sanityWishListId

  return (
    <Flex
      bg="brand.lightSand"
      py={[4, 4, 7]}
      px={[4, 4, 6]}
      mb={4}
      direction={["column", "column", "row"]}
      justifyContent="space-between"
      columnGap={10}
    >
      <HStack spacing={4} justifyContent="space-between">
        <HStack spacing={4}>
          <Box bg="brand.offWhite" p={2.5} w={10} borderRadius="round">
            <CustomIcon name={showGmLogo ? "shopping-list-gm" : "shopping-list"} width={20} height={20} />
          </Box>
          <Text fontSize={["md", "md", "xl"]}>{wishlist?.title}</Text>
        </HStack>
        <Box display={["block", "block", "none"]}>
          <WishListShareButton listId={wishlist?._key} listTitle={wishlist?.title} isSwym={isSwym} />
        </Box>
      </HStack>
      <HStack spacing={0} columnGap={4} mt={[4, 4, 0]}>
        <Box display={["none", "none", "block"]}>
          <WishListShareButton listId={wishlist?._key} listTitle={wishlist?.title} isSwym={isSwym} />
        </Box>
        <CustomLink
          to={`/shopping-list/${handleize(wishlist?.title)}?${shareParamKey}=${wishlist?._key}`}
          styles={{
            w: ["half", "half", "auto"],
            minW: [0, 38],
            display: "block",
          }}
        >
          <Button variant="outline" minW="unset" w="full">
            View List
          </Button>
        </CustomLink>

        <Button minW={[0, 38]} onClick={handleAddAllToCart} w={["half", "half", "auto"]} isLoading={addToCartLoading === wishlist?._key}>
          {addToCartLoading === wishlist?._key ? "Adding" : "Add All to Cart"}
        </Button>
      </HStack>
    </Flex>
  )
}

const MemoWishListCard = memo(WishListCard)
export { MemoWishListCard as WishListCard }
