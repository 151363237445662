import { memo, useEffect, useMemo, useState, useCallback } from "react"
import { Box, Center, Container, Heading, Spinner, Stack, Skeleton } from "@chakra-ui/react"
import { WishListCard } from "./WishListCard"
import { WishListCreate } from "./WishListCreate"
import { WishListHeader } from "./WishListHeader"
import { ProductCard } from "@components/ProductCard"
import { Listing } from "@components/Listing/Listing"
import { useWishList, useWishListNormaliser } from "@app/hooks/useWishList"
import { useWishListContext } from "@app/providers/wishlist"
import { useCore } from "@app/hooks/useCore"
import { useCustomerContext } from "@app/providers/customer"
import { navigate } from "gatsby"
import { useConfigContext } from "@app/providers/config"
import { useShopify } from "@app/hooks/useShopify"
import Sections from "@components/Sections/Sections"

import type { Props as GatsbyProps } from "@app/pages/shopping-list"
import type { PageProps } from "@root/types/global"

type WishListProps = GatsbyProps & {
  isShared?: boolean
  activeListView?: string
}

type WishListTitleProps = {
  children: React.ReactNode
  loading?: boolean
}

const WishListTitle: React.FC<WishListTitleProps> = memo(({ children, loading }) => (
  <Heading as={loading ? "div" : "p"} fontSize={["2xl", "2xl", "6xl"]} borderBottom="1.5px" pb={[4, 4, 6]} mb={[6, 6, 12]}>
    {loading ? <Skeleton w="250px" h="80px" /> : children}
  </Heading>
))

const WishListGridChildren: React.FC<{ filteredItems: any }> = memo(({ filteredItems }) => (
  <>
    {filteredItems?.map((product: any) => (
      <ProductCard key={product?.id} item={product} />
    ))}
  </>
))

const WishListListing: React.FC<PageProps<WishListProps>> = ({ activeListView, page }) => {
  const {
    helpers: { handleize },
  } = useCore()

  // State Setters
  const [checkingAuth, setCheckingAuth] = useState(true)
  const [navigating, setNavigating] = useState(false)

  // External hook calls
  const { wishLists, fetched, sharedWishList } = useWishListContext()
  const { normaliseWishList } = useWishListNormaliser()
  const {
    settings: { routes, params },
  } = useConfigContext()
  const {
    helpers: { getUrlParameter },
  } = useCore()
  const { customer, customerLoading } = useCustomerContext()
  const { getProducts } = useShopify()

  const listId = getUrlParameter(params.sanityWishListId)
  const swymListId = getUrlParameter(params.swymWishListId)

  const normalisedWishLists = useMemo(
    () => wishLists?.map((list: any) => normaliseWishList({ list })) || [],
    [normaliseWishList, wishLists]
  )

  const listToDisplay = useMemo(() => {
    const curatedLists = page?.curatedShoppingLists || []
    const allLists = [...curatedLists, ...normalisedWishLists]

    if (listId) return allLists?.find(({ _key }) => _key === listId)
    if (swymListId && sharedWishList) return normaliseWishList({ list: sharedWishList })

    return allLists?.find(({ title }) => title && activeListView && handleize(title) === handleize(activeListView))
  }, [page?.curatedShoppingLists, normalisedWishLists, listId, swymListId, sharedWishList, normaliseWishList, activeListView, handleize])

  // Redirects logged out user to login page if no params.
  // Ie on the standard shopping list page for customers
  useEffect(() => {
    const noWishList = !listId && !swymListId
    const noCustomer = !customerLoading && !customer
    const shouldRedirect = noCustomer && noWishList

    if (!customerLoading) {
      if (shouldRedirect) {
        setNavigating(true)
        return navigate(routes.LOGIN_REGISTER)
      }

      setCheckingAuth(false)
    }
  }, [customer, customerLoading, listId, routes, listToDisplay, swymListId, fetched])

  const {
    sortOptions,
    activeSortOption,
    handleSortChange,
    replaceFirstName,
    handleAddAllToCart,
    addToCartLoading,
    filterOptions,
    handleFilterChange,
    handleFilterReset,
    filteredItems,
    paginationOptions,
    products,
    loading: productsLoading,
  } = useWishList({
    rawProducts: listToDisplay?.products,
  })

  const { parsedText: parsedTitle } = useMemo(() => replaceFirstName(page?.title), [replaceFirstName, page?.title])
  const { parsedText: parsedContent } = useMemo(() => replaceFirstName(page?.content), [replaceFirstName, page?.content])

  const headerTitle = useMemo(() => listToDisplay?.title || parsedTitle, [listToDisplay?.title, parsedTitle])

  const showHeaderBorder = useMemo(() => (activeListView || listId || swymListId ? true : false), [activeListView, listId, swymListId])

  const loading = useMemo(() => navigating || checkingAuth, [navigating, checkingAuth])

  const isSwym = useMemo(() => {
    if (swymListId) {
      const isSwymList = !!normalisedWishLists?.find(wishList => wishList?._key === swymListId)
      return isSwymList
    }
    // eslint-disable-next-line
  }, [normalisedWishLists, swymListId, wishLists])

  const handleAddSwymList = useCallback(
    async list => {
      handleAddAllToCart({ products: list?.products, listToDisplay, listId: list?._key })
    },
    [handleAddAllToCart, listToDisplay]
  )

  const handleAddCuratedList = useCallback(
    async list => {
      const handles = list?.products?.map((product: any) => product?.shopify?.handle)?.filter((p: any) => p)

      const rawShopifyProducts = handles?.length
        ? await getProducts({ firstImages: 2, firstVariants: 10, handles: handles })
        : list?.products

      handleAddAllToCart({ products: rawShopifyProducts, listToDisplay, listId: list?._key })
    },
    [getProducts, handleAddAllToCart, listToDisplay]
  )

  return (
    <>
      <WishListHeader
        title={headerTitle}
        activeListView={activeListView}
        headerContent={parsedContent}
        handleAddAllToCart={() => handleAddSwymList(listToDisplay)}
        addToCartLoading={addToCartLoading}
        showBorder={showHeaderBorder}
        loading={loading}
        listId={listId || swymListId}
        isSwym={isSwym}
      />
      {listToDisplay && productsLoading ? (
        <Container>
          <Box py={8} display="flex" justifyContent="center">
            <Spinner />
          </Box>
        </Container>
      ) : listToDisplay ? (
        <>
          <Listing
            headerProps={{
              showHeader: false,
            }}
            gridProps={{
              hasItems: !!products,
              columns: [1, 3, 4, 5],
              columnGap: [10, 4],
              rowGap: [10, 4],
              children: <WishListGridChildren filteredItems={filteredItems} />,
              noItemsLabel: "No items to display",
            }}
            paginationProps={{
              computedOptions: paginationOptions,
            }}
            sortOptions={sortOptions}
            handleSortChange={handleSortChange}
            activeSortOption={activeSortOption}
            showFilters
            filterOptions={filterOptions}
            handleFilterChange={handleFilterChange}
            handleFilterReset={handleFilterReset}
          />
        </>
      ) : null}
      {!listToDisplay && (
        <Container py={[10, 10, 20]}>
          <Box mb={[6, 6, 20]}>
            <WishListTitle loading={loading}>{page?.usersListsTitle}</WishListTitle>
            <Stack mb={4}>
              <WishListCreate loading={loading} />
            </Stack>
            <Box>
              {!fetched || loading ? (
                <Center>
                  <Spinner />
                </Center>
              ) : (
                <>
                  {normalisedWishLists?.map((list: any) => (
                    <WishListCard
                      key={list?._key}
                      wishlist={list}
                      handleAddAllToCart={() => handleAddSwymList(list)}
                      addToCartLoading={addToCartLoading}
                      isSwym
                    />
                  ))}
                </>
              )}
            </Box>
          </Box>
          <Box>
            <WishListTitle>{page?.curatedListsTitle}</WishListTitle>
            {loading &&
              Array.from({ length: 5 }).map((_, idx) => (
                <Skeleton key={idx} w="full" h="90px" mb={4} startColor="brand.offWhite" endColor="brand.lightSand" />
              ))}
            {!loading &&
              page?.curatedShoppingLists?.map((list: any) => (
                <WishListCard
                  key={list?._key}
                  wishlist={list}
                  showGmLogo
                  handleAddAllToCart={() => handleAddCuratedList(list)}
                  addToCartLoading={addToCartLoading}
                />
              ))}
          </Box>
        </Container>
      )}
    </>
  )
}

const WishList: React.FC<PageProps<WishListProps>> = ({ page, location }) => {
  const {
    helpers: { isBrowser, ErrorBoundary },
  } = useCore()
  const activeListView = useMemo(
    () => (isBrowser ? location?.pathname?.split("/shopping-list/")[1] || "" : ""),
    [location?.pathname, isBrowser]
  )

  const hasSections = useMemo(
    () => (activeListView ? !!page?.wishListListingSections?.length : !!page?.wishListPageSections?.length),
    [activeListView, page?.wishListListingSections?.length, page?.wishListPageSections?.length]
  )
  const pageSectionsContent = useMemo(
    () => (activeListView ? page?.wishListListingSections : page?.wishListPageSections),
    [activeListView, page?.wishListListingSections, page?.wishListPageSections]
  )

  return (
    <ErrorBoundary>
      {isBrowser && <WishListListing activeListView={activeListView} page={page} />}
      {hasSections && <Sections page={{ content: pageSectionsContent }} />}
    </ErrorBoundary>
  )
}

export default memo(WishList)
