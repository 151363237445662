import { memo } from "react"
import { Box, Button, Container, Heading, HStack, Icon, Stack, Text, Skeleton, SkeletonText } from "@chakra-ui/react"
import { ArrowBackIcon } from "@chakra-ui/icons"
import { FiShare2 } from "react-icons/fi"
import { CustomLink } from "@components/Link"
import { useWishListContext } from "@app/providers/wishlist"
import { useCore } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"
import { CopyToClipboard } from "@components/CopyToClipboard"

type WishListHeaderProps = {
  title?: string
  children?: React.ReactNode
  showBorder?: boolean
  activeListView?: string
  headerContent?: any
  onBackClick?: () => void
  handleAddAllToCart: ({ products, listToDisplay }: { products: any; listToDisplay: any }) => Promise<void>
  addToCartLoading: string
  loading?: boolean
  isSwym?: boolean
  listId: string
}

const WishListHeader: React.FC<WishListHeaderProps> = ({
  title,
  headerContent,
  activeListView,
  handleAddAllToCart,
  addToCartLoading = "",
  showBorder = false,
  loading = false,
  isSwym = false,
  listId,
}) => {
  const { shareWishList } = useWishListContext()

  const {
    helpers: { isBrowser },
  } = useCore()
  const {
    settings: { params },
  } = useConfigContext()

  const shareParamKey = isSwym ? params.swymWishListId : params.sanityWishListId
  const linkToCopy = isBrowser ? `${window?.location?.origin}${window?.location?.pathname}?${shareParamKey}=${listId}` : ""

  const callback = () => {
    if (listId && isSwym) shareWishList(listId)
  }

  return (
    <Box
      as="section"
      pt={[12, 18]}
      pb={8}
      bg={["brand.midSand", "brand.lightSand"]}
      borderBottom={showBorder ? "1px" : "none"}
      borderColor="brand.avocado"
    >
      <Container>
        {activeListView && (
          <CustomLink to="/shopping-list">
            <HStack role="group" cursor="pointer" pr={4} mb={3}>
              <ArrowBackIcon mr={1} w={4} _groupHover={{ transform: "translateX(-4px)" }} transition="100ms ease-out" />
              <Text fontSize="md">Back to Shopping Lists</Text>
            </HStack>
          </CustomLink>
        )}
        <Stack justifyContent="space-between" direction={["column", "column", "row"]}>
          {loading && <Skeleton mb={2} h="72px" w="250px" />}
          {!loading && (
            <Heading as="h1" size="h2" mb={2}>
              {title}
            </Heading>
          )}
          {activeListView && (
            <HStack spacing={4}>
              <CopyToClipboard copiedLinkText="Copied Link!" hideIcon linkToCopy={linkToCopy} callback={callback}>
                <Button variant="outline">
                  <Icon as={FiShare2} mr={2} />
                  Share
                </Button>
              </CopyToClipboard>

              <Button onClick={handleAddAllToCart} isLoading={addToCartLoading === listId}>
                {addToCartLoading === listId ? "Adding" : "Add All to Cart"}
              </Button>
            </HStack>
          )}
        </Stack>
        {!activeListView && !loading && <Text maxW="660px">{headerContent}</Text>}
        {!activeListView && loading && <SkeletonText maxW="660px" />}
      </Container>
    </Box>
  )
}

const MemoWishListHeader = memo(WishListHeader)
export { MemoWishListHeader as WishListHeader }
