import { graphql, PageProps } from "gatsby"
import Page from "@app/components/WishList/WishList"

export type Props = PageProps<GatsbyTypes.PageWishListQuery, GatsbyTypes.PageWishListQueryVariables>

export { Head } from "@components/Head"

export const query = graphql`
  query PageWishList {
    page: sanityPageAccountWishList {
      title
      url
      content
      metadata: _rawMetadata(resolveReferences: { maxDepth: 2 })
      curatedShoppingLists {
        title
        _type
        handle
        _key
        products {
          shopify {
            handle
          }
        }
      }
      usersListsTitle
      curatedListsTitle
      wishListListingSections: _rawWishListListingSections(resolveReferences: { maxDepth: 10 })
      wishListPageSections: _rawWishListPageSections(resolveReferences: { maxDepth: 10 })
    }
  }
`

const Component: React.FC<Props> = ({ data, ...props }) => <Page {...props} {...data} isShared />
export default Component
