import { memo } from "react"
import { Text, Button, HStack, Icon } from "@chakra-ui/react"
import { useWishListContext } from "@app/providers/wishlist"
import { FiShare2 } from "react-icons/fi"
import { CopyToClipboard } from "@components/CopyToClipboard"
import { useCore } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"

type WishListShareButtonProps = {
  listId?: string
  listTitle?: string
  isSwym?: boolean
}

const WishListShareButton: React.FC<WishListShareButtonProps> = ({ listId, listTitle, isSwym = false }) => {
  const { shareWishList } = useWishListContext()

  const {
    helpers: { handleize, isBrowser },
  } = useCore()
  const {
    settings: { params, routes },
  } = useConfigContext()

  const shareParamKey = isSwym ? params.swymWishListId : params.sanityWishListId
  const linkToCopy = isBrowser
    ? `${window?.location?.protocol}//${window?.location?.host}${routes.WISHLIST}/${handleize(listTitle)}?${shareParamKey}=${listId}`
    : ""

  const callback = () => {
    if (listId && listTitle && isSwym) shareWishList(listId)
  }

  return (
    <CopyToClipboard copiedLinkText="Copied Link!" hideIcon linkToCopy={linkToCopy} callback={callback}>
      <Button variant="icon-button">
        <HStack spacing={2}>
          <Icon as={FiShare2} />
          <Text fontSize="md" display={["none", "none", "block"]}>
            Share
          </Text>
        </HStack>
      </Button>
    </CopyToClipboard>
  )
}

const MemoWishListShareButton = memo(WishListShareButton)
export { MemoWishListShareButton as WishListShareButton }
